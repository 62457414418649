<style >
@font-face {
  font-family: Axiforma-Medium;
  src: url("../assets/font/Axiforma-Medium.ttf");
}

body,
.container {
  background: #f4f5f6 !important;
  color: rgb(29, 34, 38);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.company__info {
  /*background-color: #008080;*/
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  /*flex-direction: column;*/
  justify-content: center;
  color: #fff;
}

.v-application--wrap {
  min-height: 95vh !important;
  max-width: 100%;
  position: relative;
}

.theme--light.v-label {
  color: blue;
}

.bg {
  width: 100%;
  height: 97%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../assets/webpage2.webp") no-repeat center center;
  background-size: cover;

  transform: scale(1.1);
}
.bg1 {
  width: 100%;
  height: 97%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../assets/webpage1.webp") no-repeat center center;
  background-size: cover;

  transform: scale(1.1);
}
.cardblue {
  background: #050c44 !important;
}
.cardwhite {
  background: white !important;
}
.login {
  font-size: 20px;
  font-family: Axiforma-Medium !important;
  margin-top: 30px;
  color: white !important;
}
.login1 {
  font-size: 20px;
  font-family: Axiforma-Medium !important;
  margin-top: 30px;
  color: #050c44 !important;
}
.button {
  border-radius: 6px;
  font-size: 14px;
  font-family: Axiforma-Medium !important;
  width: 100%;
  height: 44px;
  margin-top: 20px;
  background-color: white !important;
  color: #050c44 !important;
}
.button1 {
  border-radius: 6px;
  font-size: 14px;
  font-family: Axiforma-Medium !important;
  width: 100%;
  height: 44px;
  margin-top: 20px;
  background-color: #050c44 !important;
  color: white !important;
}
.not {
  color: white !important;
}
.not1 {
  color: #050c44 !important;
}
.forgo {
  color: white !important;
}
.forgo1 {
  color: #050c44 !important;
}
.v-input__slot {
  /* background: white !important; */
}
/*.row {
  background-color: #050c44 !important;
}*/
</style>
 <template>
  <v-app>
    <v-overlay :value="overlay_load" z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-content style="min-height: 95vh !important">
      <v-container fluid fill-height v-if="show_hide_mob_btn == 1">
        <span :class="background_color"></span>
        <v-row class="center" justify="center">
          <v-col cols="12" md="4" lg="3" sm="4">
            <v-card
              :class="card_color"
              style="
                width: 500px;
                max-height: 610px;
                text-align: center;
                border-radius: 10px;

                margin-top: 50px;
              "
            >
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col cols="6" class="center">
                      <v-img
                        v-if="!btn"
                        class="logo"
                        max-height="55"
                        max-width="177"
                        style="top: 20px; margin-left: 10px"
                        src="../assets/MS-LOGO-1-blue.png"
                      ></v-img
                      ><v-img
                        v-if="btn"
                        class="logo"
                        max-height="55"
                        max-width="160"
                        style="top: 20px; margin-left: 10px"
                        src="../assets/tectalik logo.png"
                      ></v-img>
                    </v-col>
                    <v-col cols="3"></v-col>
                  </v-row>
                  <v-row style="justify-content: center">
                    <v-col cols="12">
                      <p class="login" :class="login">Login to Tectalik</p>
                    </v-col>
                    <v-col cols="10">
                      <v-text-field
                        v-model="email"
                        outlined
                        hide-details
                        placeholder="Email or Username"
                        @keypress.enter="$refs[`indx_of_pwd`].focus()"
                        required
                        style="
                          border-radius: 6px;
                          font-size: 14px;
                          font-family: Axiforma-Medium !important;
                          width: 360px;
                          height: 100%;
                          background-color: white !important ;
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="10">
                      <!--            <v-text-field
                        v-if="showPassword"
                        outlined
                        v-model="password"
                        hide-details
                        type="text"
                        placeholder="Password"
                        required
                        style="color: white !important"
                      >
                      </v-text-field> -->
                      <v-text-field
                        outlined
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        hide-details=""
                        placeholder="Password"
                        required
                        style="
                          font-size: 14px;
                          border-radius: 6px;
                          font-family: Axiforma-Medium !important;
                          width: 360px;
                          height: 100%;
                          background-color: white !important;
                        "
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="10" v-if="prefixflag">
                      <v-text-field
                        v-model="prefix"
                        placeholder="Sub Domain"
                        outlined
                        hide-details
                        required
                        style="
                          border-radius: 6px;
                          font-size: 14px;
                          font-family: Axiforma-Medium !important;
                          width: 360px;
                          height: 100%;
                          background-color: white !important ;
                        "
                        @change="changetoLowercase(prefix)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="10">
                      <v-btn
                        :class="button"
                        class="button justify-center"
                        dark
                        @click="validate()"
                        @keyup.enter="validate()"
                      >
                        SUBMIT
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <p
                        style="
                          font-size: 14px;
                          cursor: pointer;
                          font-family: Axiforma-Medium !important;
                        "
                      >
                        <a class="not" :class="not" @click="forgot"
                          >Not able to log in?</a
                        >
                        |<a class="not" :class="not"> Contact us</a>
                      </p>
                    </v-col>
                    <v-col cols="6" style="padding-top: 25px !important">
                      <!-- <span
                        class="forgo"
                        :class="forgo"
                        style=" cursor: pointer; font-size: 14px; font-family:Axiforma-Medium !important; "
                        @click="forgot"
                      >
                        Forgot Password?
                      </span> -->
                    </v-col>
                    <v-col cols="3"></v-col>
                    <v-col cols="3" style="padding-top: 0px !important">
                      <span
                        :class="background"
                        style="color: white; cursor: pointer; font-size: 14px"
                      >
                        <v-switch @change="back()" :dark="!btn"></v-switch>
                      </span>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-footer style="background: center; width: 100vw">
          <v-row style="justify-content: center">
            <v-col cols="12" class="d-flex justify-center align-center" >
              <v-img
              v-if="showimage == false"
                max-width="100"
                size="50px"
                src="../assets/align-logo 2.png"
              ></v-img> 
               <v-img
                  v-if="showimage"
               max-width="100"
                size="70px"
                src="../assets/align-logo 2.png"
              ></v-img> 
            </v-col>
            </v-row> 
        </v-footer>  -->
      </v-container>
      <v-container v-if="show_hide_mob_btn == 2">
        <v-row class="center" justify="center">
          <v-col cols="12" md="4" lg="3" sm="4">
            <v-card
              fullscreen
              persistent
              width="600px"
              style="
                left: -4%;
                min-width: 107%;
                max-height: 35%;
                text-align: center;
                margin-top: -43px;
              "
            >
              <div
                style="background: rgb(5 12 69) !important; margin-top: -6px"
              >
                <img
                  src="../assets/company-overlay.png"
                  width="100%"
                  height="300px"
                  style="opacity: 40%; blend: lighten"
                  class="pa-0"
                />
              </div>
            </v-card>
            <v-card
              z-index="5"
              style="
                height: 100vh;
                overflow: visible;
                margin-top: -41px;
                background: rgb(255, 255, 255);
                border-radius: 40px 40px 0px 0px;
                left: -4%;
                min-width: 107%;
              "
            >
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col cols="3"></v-col>
                  </v-row>
                  <div>
                    <v-row
                      style="
                        text-align: left;
                        margin-top: 30px;
                        margin-left: 29px;
                      "
                    >
                      <v-col cols="12">
                        <h3
                          style="
                            font-family: 'Axiforma';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 39px;
                            color: #000000;
                          "
                        >
                          Hey there!
                        </h3>
                        <!-- </v-col>
                        <v-col cols="12"> -->
                        <p
                          style="
                            margin-top: 7px;
                            font-weight: 300;
                            font-size: 18px;
                            line-height: 28px;
                            color: #050c44;
                          "
                        >
                          Please login to continue
                        </p>
                      </v-col>
                      <v-col
                        cols="10"
                        style="
                          border-radius: 6px;
                          font-family: 'Axiforma';
                          font-style: normal;
                          font-weight: 500;
                          font-size: 18px;
                          line-height: 29px;
                          color: #050c44;
                          margin-top: -7%;
                          width: 360px;
                          height: 100%;
                          background-color: white !important;
                        "
                      >
                        <p>Email</p>
                        <v-text-field
                          v-model="email"
                          hide-details
                          required
                          style="margin-top: -37px"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="10"
                        style="
                          border-radius: 6px;
                          font-family: 'Axiforma';
                          font-style: normal;
                          font-weight: 500;
                          font-size: 18px;
                          line-height: 29px;
                          color: #050c44;
                          width: 360px;
                          height: 100%;
                          background-color: white !important;
                        "
                      >
                        <p>Password</p>
                        <!--            <v-text-field
                        v-if="showPassword"
                        outlined
                        v-model="password"
                        hide-details
                        type="text"
                        placeholder="Password"
                        required
                        style="color: white !important"
                        >
                        </v-text-field> -->
                        <v-text-field
                          v-model="password"
                          :type="showPassword ? 'text' : 'password'"
                          hide-details=""
                          required
                          style="margin-top: -37px"
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="showPassword = !showPassword"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="10"
                        v-if="prefixflag"
                        style="
                          border-radius: 6px;
                          font-family: 'Axiforma';
                          font-style: normal;
                          font-weight: 500;
                          font-size: 18px;
                          line-height: 29px;
                          color: #050c44;
                          width: 360px;
                          height: 100%;
                          background-color: white !important;
                        "
                      >
                        <p>Sub Domain</p>
                        <v-text-field
                          v-model="prefix"
                          hide-details
                          required
                          style="margin-top: -37px"
                          @change="changetoLowercase(prefix)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-btn
                          outlined
                          dense
                          persistent-hint
                          :class="button"
                          class="button justify-center"
                          rounded
                          style="
                            background: rgb(5, 12, 69) !important;
                            border-radius: 50px;
                            color: white;
                            margin-left: 7px;
                          "
                          @click="validate()"
                        >
                          <p
                            style="
                              margin-top: 17px;
                              text-transform: none;
                              color: white;
                            "
                          >
                            Log in
                          </p>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" style="margin-left: 29px">
                        <p
                          style="
                            font-size: 14px;
                            cursor: pointer;
                            font-family: Axiforma-Medium !important;
                          "
                        >
                          <a @click="forgot" style="color: #050c44"
                            >Not able to log in?</a
                          >
                          |<a style="color: #818181"> Contact us</a>
                        </p>
                      </v-col>
                      <v-col cols="6" style="padding-top: 25px !important">
                        <!-- <span
                        class="forgo"
                        :class="forgo"
                        style=" cursor: pointer; font-size: 14px; font-family:Axiforma-Medium !important; "
                        @click="forgot"
                      >
                        Forgot Password?
                      </span> -->
                      </v-col>
                      <v-col cols="3"></v-col>
                      <!-- <v-col cols="3" style="padding-top: 0px !important">
                        <span
                          :class="background"
                          style="color: white; cursor: pointer; font-size: 14px"
                        >
                          <v-switch @change="back()" :dark="!btn"></v-switch>
                        </span>
                      </v-col> -->
                    </v-row>
                  </div>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <!-- <v-footer style="background: center; width: 100vw">
          <v-row style="justify-content: center">
            <v-col cols="12" class="d-flex justify-center align-center" >
              <v-img
              v-if="showimage == false"
                max-width="100"
                size="50px"
                src="../assets/align-logo 2.png"
              ></v-img> 
               <v-img
                  v-if="showimage"
               max-width="100"
                size="70px"
                src="../assets/align-logo 2.png"
              ></v-img> 
            </v-col>
            </v-row> 
        </v-footer>  -->
    <!-- </v-container> -->
    <!-- </v-content> -->
  </v-app>
</template>

 <script>
import axios from "axios";
import moment from "moment";
import Package from "/package.json";
import { messaging } from "../views/FMS/firebase";
import { getToken } from "firebase/messaging";
export default {
  mounted: function () {
    let domain = window.location.host.split(".");
    if (
      domain.length === 0 ||
      domain == null ||
      domain == "" ||
      domain == undefined ||
      domain == "localhost"
    ) {
      this.prefixflag = true;
    }

    // this.prefixflag = true;
  },
  data() {
    return {
      applicationVersion: Package.version,
      resultats: null,
      btn: true,
      background: null,
      background_color: "bg",
      card_color: "cardblue",
      login: "login",
      button: "button",
      not: "not",
      forgo: "forgo",
      device_token: null,
      email: "",
      password: "",
      valid: "",
      domain: "",
      showPassword: false,
      overlay_load: false,
      step: null,
      show_hide_mob_btn: 0,
      full_or_modal: 0,
      window: {
        width: 0,
        height: 0,
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/.test(
            v
          ) ||
          // /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      checkbox: false,
      mini: false,
      showimage: false,
      window: {
        width: 0,
        height: 0,
      },
      iflag: 0,
      prefix: null,
      prefixflag: false,
      logged_user_id: null,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.back();
    // this.resultats = JSON.parse(localStorage.getItem("resultat") || "");
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.back();
  },

  methods: {
    requestPermission() {
      console.log("Requesting permission...");

      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted. login");
          getToken(messaging, {
            vapidKey:
            "BMy2wqMcf-2AcJJ7sk-gzMrmBRMmztdQ5UqSGDnfJcQceQ7-kLk8t2StTk3zh1qXCk3Dqd-tvDtjpvty7hZkR5Q",
          })
            .then((currentToken) => {
              if (currentToken) {
                this.device_token = currentToken;
                console.log(this.device_token, "current token");
                this.store_token();
              } else {
                this.device_token = null;
                console.log(
                  "No registration token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
              this.global_push_toast(
                "error",
                `${err.response.status} - ${err.response.statusText} - An error occurred while retrieving token`
              );
            });
        } else {
          console.log("No Permission!");
          this.global_push_toast(
            "error",
            "No Permission!"
          );
        }
      });
      setTimeout(() => { this.update_user_data()}, 3000);
    },
    update_user_data(){
      let prefix = "cmp_" + this.prefix;
      prefix = prefix == 'cmp_admin' ? 'public' : prefix ;
        const user_dataset = {
          user_dataset: {
            logged_date: moment(),
            expiry_date: moment().add(7, "days")
          }
        }
        this.$api
          .put("/update_user_logged_data/" + this.logged_user_id + "/" + prefix, user_dataset)
          .then((response) => {
            let expiry_date = moment().add(7, "days").format("YYYY-MM-DD");
            console.log(expiry_date,"expiry_date");
            localStorage.setItem("expiry_date", expiry_date);
            const name = localStorage.getItem("expiry_date");
            console.log(name,"name");
          })
          .catch((error) => {
            console.log(error,"error");
            this.global_push_toast(
            "error",
            `${error.response.status} - ${error.response.statusText}`
          );
          });
    },
    store_token() {
      console.log(this.device_token);
      const user_id = parseInt(localStorage.getItem("USER_ID"));
      const name = localStorage.getItem("USER_NAME");
      const email = localStorage.getItem("USER_EMAIL");
      const entity_id = parseInt(localStorage.getItem("ENTITY_ID"));
      axios.get("/get_users_device/" + user_id).then((response) => {
        const data = response.data.data;

        if (data.length) {
          let tokenArr = data.filter(
            (obj) => obj.device_token == this.device_token
          );
          console.log(tokenArr);
          if (tokenArr.length) {
            let tokenId = tokenArr[0].id;

            axios.put("/update_users_device/" + tokenId).then((response) => {
              console.log(response);
            });
          } else {
            const notification_token = {
              notification_token: {
                user_id: user_id,
                name: name,
                email: email,
                device_token: this.device_token,
                device_flag: true,
                entity_id: entity_id,
                active: true,
              },
            };
            axios
              .post("/notification_token", notification_token)
              .then((response) => {
                console.log(response);
              });
          }
        } else {
          const notification_token = {
            notification_token: {
              user_id: user_id,
              name: name,
              email: email,
              device_token: this.device_token,
              device_flag: true,
              entity_id: entity_id,
              active: true,
            },
          };
          axios
            .post("/notification_token", notification_token)
            .then((response) => {
              console.log(response);
            });
        }
      });
    },
    back() {
      // console.log(this.btn,"this.btn");
      if (this.btn) {
        this.btn = false;
        this.background_color = "bg";
        this.card_color = "cardblue";
        this.login = "login";
        this.button = "button";
        this.not = "not";
        this.forgo = "forgo";

        // this.background = 'bg1'
      } else {
        this.btn = true;
        this.background_color = "bg1";
        this.card_color = "cardwhite";
        this.login = "login1";
        this.button = "button1";
        this.not = "not1";
        this.forgo = "forgo1";
        // this.background = 'bg'
      }
    },
    // handleResize() {
    //   this.window.width = window.innerWidth;
    //   this.window.height = window.innerHeight;
    //   if (this.window.width <= 576) {
    //     this.showimage = true;
    //   } else {
    //     this.showimage = false;
    //   }

    // },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width <= 576) {
        this.show_hide_mob_btn = 2;
        this.full_or_modal = 1;
      } else {
        this.show_hide_mob_btn = 1;
        this.full_or_modal = 0;
      }
    },

    validate() {
      if (this.prefixflag) {
        if (!this.prefix) {
          return;
        }
      } else {
        let domain = window.location.host.split(".");
        // console.log(domain, "domain local");
        this.prefix = domain[0];
      }

      this.overlay_load = true;
      window.axios = require("axios");
      let mail = this.email;
      if (mail) {
        mail = mail.trim();
      }
      let pass_word = this.password;
      if (pass_word) {
        pass_word = pass_word.trim();
      }

      const login_dataset = {
        email: mail,
        password: pass_word,
        prefix: "cmp_" + this.prefix,
      };
      axios
        .post("api/sessions/login", login_dataset)
        .then((response) => {
          this.Successdataset(response.data, this.prefix);
        })
        .catch((error) => {
          console.log(error);
          this.overlay_load = false;
          this.global_push_toast("error", "server error");
        });
    },

    Successdataset(data, domain) {
      // console.log(data, "result");
      // localStorage.post(this.applicationVersion)
      // this.resultats.push(this.applicationVersion)
      localStorage.setItem("resultat", this.applicationVersion);
      if (data.result == "success") {
        localStorage.setItem("TOKEN", data.token);
        let features_list = [];
        let product_list = [];
        let entitydata = [];
        if (domain != "admin") {
          // console.log(domain, " = domain");
          axios
            .get("tapi/get_features_by_role/" + data.user_id)
            .then((response) => {
              // console.log(response.data,'get_features_by_role')
              if (response.data.data[0].entity_list.length == 0) {
                this.overlay_load = false;
                alert("Client is Deactivated");
                localStorage.clear();
                return;
              }
              localStorage.setItem(
                "entitydata",
                JSON.stringify(response.data.data[0].entity_list)
              );

              let enty = response.data.data[0].entity_list;
              if (enty.length == 1) {
                localStorage.setItem("ENTITY_ID", enty[0].id);
                localStorage.setItem("entity_name", enty[0].name);
                localStorage.setItem(
                  "ENT_FINYEAR",
                  enty[0].financial_year != null
                    ? enty[0].financial_year
                    : "__NA__"
                );
              }

              let employee_dataset = response.data.emp_details;
              localStorage.setItem("Self_Rating_Setting", "yes");

              // let calc_settings = response.data.calc_settings;
              // console.log("calc_settings",calc_settings );
              // if (calc_settings.length)
              // {
              //   let calc = calc_settings[0].show == true
              //     ? "yes"
              //     : "no";
              //   localStorage.setItem("kpi_calc", calc);
              // } else {
              //   localStorage.setItem("kpi_calc", "no");
              // }

              if (employee_dataset.length) {
                localStorage.setItem("LOGGED_EMP_ID", employee_dataset[0].id);
                localStorage.setItem(
                  "LOGGED_EMP_NO",
                  employee_dataset[0].empno
                );
              } else {
                localStorage.setItem("LOGGED_EMP_ID", 0);
                localStorage.setItem("LOGGED_EMP_NO", 0);
              }

              let fl = response.data.data[0].roles_list;

              this.set_local_storage(
                response.data.modules_by_users,
                response.data.screen_by_users
              );
              // products_by_users
              let pl = [];
              let product_list_item = response.data.products_by_users;
              if (product_list_item.length != 0) {
                pl = product_list_item.map((a) => a.product_code);
              } else {
                pl = response.data.data[0].product_list;
              }

              if (fl == "" || fl == null) {
                features_list = [];
              } else {
                features_list = fl;
              }
              if (pl == "" || pl == null) {
                product_list = [];
              } else {
                product_list = pl;
              }

              // console.log(product_list, 'product_list')
              localStorage.setItem("tectalik_product_list", product_list);
              localStorage.setItem("features_list", features_list);

              localStorage.setItem("USER_ID", data.user_id);
              this.logged_user_id = data.user_id;
              localStorage.setItem("USER_EMAIL", data.user_email);
              localStorage.setItem("USER_NAME", data.user_name);
              localStorage.setItem(
                "BRANCH_ID",
                response.data.data[0].branch_id
              );
              var product_get = localStorage.getItem("tectalik_product_list");
              localStorage.setItem("domain", domain);
              product_list = [];
              if (
                product_get != null &&
                product_get != "" &&
                product_get != undefined &&
                product_get.length != 0
              ) {
                product_list = product_get.split(",");
              }
              if (this.window.width <= 576) {
                if (product_list.includes("TECTALIK_FMS")) {
                  this.$router.push({
                    path: "/welcome_page",
                  });
                } else {
                  this.$router.push({
                    path: "/show_list",
                  });
                }
              } else {
                this.$router.push({
                  path: "/show_list",
                });
              }
            })
            .then(() => this.requestPermission())
            .catch((error) => console.log(error, "error"));
        } else {
          axios
            .get("api/get_screen_and_user_data_by_role/", {
              params: {
                user_id: data.user_id,
              },
            })
            .then((response) => {
              // console.log(response.data.common_screen_dataset,'get_screen_and_user_data_by_role')
              localStorage.setItem(
                "USER_TYPE",
                response.data.data.user_type_code
              );
              localStorage.setItem(
                "USER_TYPES",
                response.data.data.user_type_code
              );

              let screen_dataset = [];
              let module_dataset = [];
              localStorage.setItem(
                "SCREEN_RIGHT_DATASET",
                JSON.stringify(screen_dataset)
              );
              localStorage.setItem(
                "MODULE_RIGHTS_DATASET",
                JSON.stringify(module_dataset)
              );

              let common_screen_dataset = response.data.common_screen_dataset;
              localStorage.setItem(
                "COMMON_SCREEN_RIGHT_DATASET",
                JSON.stringify(common_screen_dataset)
              );

              localStorage.setItem("tectalik_product_list", product_list);
              localStorage.setItem("features_list", features_list);
              localStorage.setItem("TOKEN", data.token);
              localStorage.setItem("USER_ID", data.user_id);
              this.logged_user_id = data.user_id;
              localStorage.setItem("USER_EMAIL", data.user_email);
              localStorage.setItem("USER_NAME", data.user_name);
              localStorage.setItem("domain", domain);

              this.$router.push({
                path: "/admin_landing",
              });
            })
            .then(() => this.requestPermission())
            .catch((error) => {
              this.overlay_load = false;
              alert("Invalid credentails");
              return;
            });
        }
      } else {
        this.overlay_load = false;
        alert("Invalid credentails");
        return;
      }
    },
    forgot() {
      let domain = window.location.host.split(".");
      if (domain[0] == "admin") {
        this.$router.push({
          path: "/forgotpassword_admin?email=" + this.email,
        });
      } else {
        this.$router.push({ path: "/forgotpassword?email=" + this.email });
      }
    },
    set_local_storage(module_dataset = [], screen_dataset = []) {
      localStorage.setItem(
        "MODULE_RIGHTS_DATASET",
        JSON.stringify(module_dataset)
      );
      for (let i = 0; i < screen_dataset.length; i++) {
        screen_dataset[i]["hide_all"] = false;
        screen_dataset[i]["module_name"] = false;
        for (let j = 0; j < module_dataset.length; j++) {
          if (screen_dataset[i].modules_rights_id == module_dataset[j].id) {
            screen_dataset[i]["hide_all"] = module_dataset[j].active;
            screen_dataset[i]["module_name"] = module_dataset[j].module_name;
          }
        }
      }
      localStorage.setItem(
        "SCREEN_RIGHT_DATASET",
        JSON.stringify(screen_dataset)
      );
    },
    changetoLowercase($subdomain) {
      this.prefix = $subdomain.toLowerCase().trim();
      // console.log(this.prefix,"$prefix");
    },
  },
};
</script>