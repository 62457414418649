import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

const firebaseConfig = {
  apiKey: "AIzaSyCZtWcRQJMv33JLBTCAKUCxiILY3Lr_6vQ",
  authDomain: "escon-53e13.firebaseapp.com",
  projectId: "escon-53e13",
  storageBucket: "escon-53e13.appspot.com",
  messagingSenderId: "373156570574",
  appId: "1:373156570574:web:1be139f36c5fc520ee779f",
  measurementId: "G-2HCJD0QPZG"
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
